/* eslint-disable indent */
/* eslint-disable no-console */
import React, { useEffect, useCallback, useState, useRef } from "react";
import { Dropdown } from "react-bootstrap";
import toast from "react-hot-toast";
import { FaEdit, FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer, LabelList } from "recharts"; // Importa LabelList
import { useAuthContext } from "../../context/auth";
import { getCategorias, getOportunidades, updateOportunidad } from "../../utils";
import "./styles.scss";

export const VentasDetalle = () => {
  const [oportunidades, setOportunidades] = useState([]);
  const [categorias, setCategorias] = useState();
  const [loading, setLoading] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [deletedOpportunity, setDeletedOpportunity] = useState(null);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [orderBy, setOrderBy] = useState(null);
  const [orderDesc, setOrderDesc] = useState(false);
  const [filtroCliente, setFiltroCliente] = useState("");
  const [filtroMes, setFiltroMes] = useState([]);
  const [filtroEstatus, setFiltroEstatus] = useState([]); // Ahora es un array
  const [filtroIntermediario, setFiltroIntermediario] = useState("");
  const [filtroCategoria, setFiltroCategoria] = useState("");
  const [filtroAno, setFiltroAno] = useState(["2024"]); // Default seleccionado en 2024

  const { pais } = useAuthContext();

  const navigate = useNavigate();

  const goToOportunidad = useCallback((id) => navigate(`/admin/oportunidad/${id}`), [navigate]);

  const localString = useCallback(
    (pais) =>
      ({
        Chile: "es-CL",
        Perú: "es-PE",
        Uruguay: "es-UY",
      })[pais] || "es-UY",
    []
  );

  const moneda = useCallback(
    (pais) =>
      ({
        Chile: "$",
        Perú: "S/",
        Uruguay: "$",
      })[pais] || "$",
    []
  );

  const decimales = useCallback(
    (pais) =>
      ({
        Chile: 0,
        Perú: 2,
        Uruguay: 0,
      })[pais] || 0,
    []
  );

  const mesEnLetras = useCallback((mes) => {
    const mesesEnLetras = ["ENE", "FEB", "MAR", "ABR", "MAY", "JUN", "JUL", "AGO", "SEP", "OCT", "NOV", "DIC"];
    return mesesEnLetras[parseInt(mes) - 1] || "";
  }, []);

  const handleMesChange = useCallback((e) => {
    const selectedMonths = Array.from(
      e.target.closest(".dropdown-menu").querySelectorAll("input[type=checkbox]:checked"),
      (checkbox) => checkbox.value
    );
    setFiltroMes(selectedMonths);
  }, []);

  const renderMesOptions = () =>
    ["ENE", "FEB", "MAR", "ABR", "MAY", "JUN", "JUL", "AGO", "SEP", "OCT", "NOV", "DIC"].map((mes, index) => (
      <div key={index} className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          id={`mes${index}`}
          value={index < 9 ? `0${index + 1}` : `${index + 1}`}
          checked={filtroMes.includes(index < 9 ? `0${index + 1}` : `${index + 1}`)}
          onChange={handleMesChange}
          style={{ marginLeft: "-15px" }} // Aplica el margen izquierdo aquí
        />
        <label
          className="form-check-label"
          htmlFor={`mes${index}`}
          style={{ marginLeft: "10px" }} // Añade margen al texto del checkbox
        >
          {mes}
        </label>
      </div>
    ));

  const renderClientesOptions = () => {
    const clientesOrdenados = [...new Set(oportunidades.map((oportunidad) => oportunidad.cliente))].sort();
    return clientesOrdenados.map((cliente, index) => (
      <option key={index} value={cliente}>
        {cliente}
      </option>
    ));
  };

  const renderIntermediariosOptions = () => {
    const intermediariosOrdenados = [...new Set(oportunidades.map((oportunidad) => oportunidad.intermediario))].sort();
    return intermediariosOrdenados.map((intermediario, index) => (
      <option key={index} value={intermediario}>
        {intermediario}
      </option>
    ));
  };

  const handleEstatusChange = (e) => {
    const value = e.target.value;
    setFiltroEstatus((prev) => (prev.includes(value) ? prev.filter((estatus) => estatus !== value) : [...prev, value]));
  };

  const renderEstatusOptions = () =>
    Object.entries(customStatusOrder).map(([estatus, _], index) => (
      <div key={index} className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          id={`estatus${index}`}
          value={estatus}
          checked={filtroEstatus.includes(estatus)}
          onChange={handleEstatusChange}
          style={{ marginLeft: "-15px" }} // Ajuste fino para el checkbox
        />
        <label
          className="form-check-label"
          htmlFor={`estatus${index}`}
          style={{ marginLeft: "10px" }} // Añade margen al texto del checkbox
        >
          {estatus}
        </label>
      </div>
    ));

  const handleDelete = async (id) => {
    try {
      setLoading(true);
      await updateOportunidad({ id, oportunidad: { eliminada: true } });
      const updatedOportunidades = oportunidades.map((oportunidad) =>
        oportunidad.id === id ? { ...oportunidad, eliminado: true } : oportunidad
      );
      setOportunidades(updatedOportunidades);
      toast.success("La oportunidad se eliminó correctamente");
      setShowConfirmationModal(false);
      setDeletedOpportunity(null);
      setTimeout(() => {}, 1000);
      setShouldRefresh(true);
    } catch (error) {
      console.error("Error deleting oportunidad:", error);
      toast.error("Hubo un error al eliminar la oportunidad, prueba de nuevo.");
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmationModal = (oportunidad) => {
    setShowConfirmationModal(true);
    setDeletedOpportunity(oportunidad);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Obtener todas las oportunidades sin filtrar por año
        const newOportunidades = await getOportunidades({ pais });
        setOportunidades(newOportunidades);

        // Establecer las categorías
        const newCategorias = await getCategorias();
        setCategorias(newCategorias);

        // Establecer el filtro de año por defecto en 2024 como array
        setFiltroAno(["2024"]); // Cambiar "2024" a ["2024"]
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [pais]);

  const renderCategoriaOptions = () =>
    categorias
      ? categorias.map((categoria, index) => (
          <option key={index} value={categoria.nombre}>
            {categoria.nombre}
          </option>
        ))
      : null;

  const handleCategoriaChange = (e) => {
    setFiltroCategoria(e.target.value);
  };

  const handleAnoChange = (e) => {
    const value = e.target.value;
    setFiltroAno((prev) => (prev.includes(value) ? prev.filter((ano) => ano !== value) : [...prev, value]));
  };

  const oportunidadesFiltradas = oportunidades.filter((oportunidad) => {
    const clienteMatch = !filtroCliente || oportunidad.cliente.includes(filtroCliente);
    const mesMatch = filtroMes.length === 0 || filtroMes.includes(oportunidad.mes);
    const estatusMatch = filtroEstatus.length === 0 || filtroEstatus.includes(oportunidad.estatus);
    const intermediarioMatch = !filtroIntermediario || oportunidad.intermediario.includes(filtroIntermediario);
    const categoriaMatch = !filtroCategoria || oportunidad.categoria === filtroCategoria;

    // Si oportunidad.ano es null o undefined, trátalo como si fuera 2024
    const anoOportunidad = oportunidad.ano || 2024;
    const anoMatch = filtroAno.length === 0 || filtroAno.includes(anoOportunidad.toString());

    return (
      clienteMatch &&
      mesMatch &&
      estatusMatch &&
      intermediarioMatch &&
      categoriaMatch &&
      anoMatch &&
      !oportunidad.eliminada
    );
  });

  useEffect(() => {
    if (shouldRefresh) {
      window.location.reload();
    }
  }, [shouldRefresh]);

  const customStatusOrder = {
    "100%": 0,
    "90%": 1,
    "75%": 2,
    "50%": 3,
    "0%": 4,
  };

  const handleSort = (column) => {
    if (orderBy === column) {
      setOrderDesc(!orderDesc);
    } else {
      setOrderBy(column);
      setOrderDesc(false);
    }
  };

  const sortedOportunidades = [...oportunidadesFiltradas].sort((a, b) => {
    if (orderBy === "estatus") {
      const orderA = customStatusOrder[a.estatus];
      const orderB = customStatusOrder[b.estatus];
      return orderDesc ? orderB - orderA : orderA - orderB;
    } else if (orderBy) {
      const valueA = a[orderBy];
      const valueB = b[orderBy];

      if (typeof valueA === "string" && typeof valueB === "string") {
        return orderDesc ? valueB.localeCompare(valueA) : valueA.localeCompare(valueB);
      }

      if (typeof valueA === "number" && typeof valueB === "number") {
        return orderDesc ? valueB - valueA : valueA - valueB;
      }
    }

    // Si no se especifica columna o tipo de datos no se puede comparar, retornar 0 para no ordenar.
    return 0;
  });

  const renderArrow = (column) => {
    if (orderBy === column) {
      return orderDesc ? "↓" : "↑";
    } else {
      return null;
    }
  };

  const mesButtonRef = useRef(null);

  useEffect(() => {
    if (mesButtonRef.current) {
      mesButtonRef.current.addEventListener("click", (e) => {
        e.stopPropagation();
        e.preventDefault();
        mesButtonRef.current.nextSibling.classList.toggle("show");
      });

      document.addEventListener("click", () => {
        if (mesButtonRef.current && mesButtonRef.current.nextSibling) {
          mesButtonRef.current.nextSibling.classList.remove("show");
        }
      });
    }
  }, []);

  const sumaImportePorEstatus = (estatus) =>
    oportunidadesFiltradas
      .filter((oportunidad) => oportunidad.estatus === estatus)
      .reduce((acc, oportunidad) => acc + oportunidad.importe, 0);

  const sumaImportes100 = sumaImportePorEstatus("100%");
  const sumaImportes90 = sumaImportePorEstatus("90%");
  const sumaImportes75 = sumaImportePorEstatus("75%");
  const sumaImportes50 = sumaImportePorEstatus("50%");
  const sumaImportes0 = sumaImportePorEstatus("0%");

  // Cálculo del total para mantener la barra siempre llena
  const totalImportes = sumaImportes100 + sumaImportes90 + sumaImportes75 + sumaImportes50 + sumaImportes0;

  // Calcular los porcentajes de cada segmento basado en el total
  const data = [
    {
      name: "Total",
      "100%": (sumaImportes100 / totalImportes) * 100,
      "90%": (sumaImportes90 / totalImportes) * 100,
      "75%": (sumaImportes75 / totalImportes) * 100,
      "50%": (sumaImportes50 / totalImportes) * 100,
      "0%": (sumaImportes0 / totalImportes) * 100,
      empty: totalImportes === 0 ? 100 : 0, // Agrega un campo "empty" para cuando no hay datos
    },
  ];

  // Función para determinar si una barra es la primera o última visible
  const getRadius = (key, data) => {
    // Filtra solo los keys con valor > 0
    const keys = Object.keys(data).filter((key) => data[key] > 0);

    // Obtiene el primer y el último key con valor > 0
    const firstKey = keys[0];
    const lastKey = keys.at(-1);

    // Si solo hay un segmento visible, redondea todas las esquinas
    if (firstKey === key && lastKey === key) {
      return [10, 10, 10, 10]; // Todas las esquinas redondeadas
    } else if (firstKey === key) {
      // Si es el primer segmento visible, redondea las esquinas izquierdas
      return [10, 0, 0, 10];
    } else if (lastKey === key) {
      // Si es el último segmento visible, redondea las esquinas derechas
      return [0, 10, 10, 0];
    }

    return [0, 0, 0, 0]; // Barras intermedias sin redondear
  };

  return (
    <div>
      <div className="row justify-content-center mt-2 mb-2">
        <div className="col-md-2">
          <select className="form-control" value={filtroCliente} onChange={(e) => setFiltroCliente(e.target.value)}>
            <option value="">Filtrar por cliente</option>
            {renderClientesOptions()}
          </select>
        </div>
        <div className="col-md-2">
          <Dropdown>
            <Dropdown.Toggle variant="white border" id="dropdown-basic" className="form-control text-start">
              {filtroMes.length > 0 ? filtroMes.map((mes) => mesEnLetras(mes)).join(", ") : "Filtrar por mes/meses"}
            </Dropdown.Toggle>
            <Dropdown.Menu>{renderMesOptions()}</Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="col-md-2">
          <Dropdown>
            <Dropdown.Toggle variant="white border" id="dropdown-status" className="form-control text-start">
              {filtroEstatus.length > 0 ? filtroEstatus.join(", ") : "Filtrar por status"}
            </Dropdown.Toggle>
            <Dropdown.Menu>{renderEstatusOptions()}</Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="col-md-2">
          <select className="form-control" value={filtroCategoria} onChange={handleCategoriaChange}>
            <option value="">Filtrar por categoría</option>
            {renderCategoriaOptions()}
          </select>
        </div>
        <div className="col-md-2">
          <select
            className="form-control"
            value={filtroIntermediario}
            onChange={(e) => setFiltroIntermediario(e.target.value)}
          >
            <option value="">Filtrar por intermediario</option>
            {renderIntermediariosOptions()}
          </select>
        </div>
        <div className="col-md-2">
          <Dropdown>
            <Dropdown.Toggle variant="white border" id="dropdown-basic" className="form-control text-start">
              {filtroAno.length > 0 ? filtroAno.join(", ") : "Filtrar por año"}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {["2023", "2024", "2025"].map((ano, index) => (
                <div key={index} className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id={`ano${index}`}
                    value={ano}
                    checked={filtroAno.includes(ano)}
                    onChange={handleAnoChange}
                    style={{ marginLeft: "-15px" }} // Ajuste fino para el checkbox
                  />
                  <label className="form-check-label" htmlFor={`ano${index}`} style={{ marginLeft: "10px" }}>
                    {ano}
                  </label>
                </div>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>

      <div className="mb-2 d-flex justify-content-center">
        <div className="d-flex justify-content-between" style={{ width: "80%" }}>
          <div className="text-center p-2 bg-success border border-dark m-3 text-white" style={{ borderRadius: "4px" }}>
            <div>
              <strong>100%</strong>
            </div>
            <div>
              {sumaImportes100 > 0
                ? `${moneda(pais)} ${sumaImportes100.toLocaleString(localString(pais), { maximumFractionDigits: decimales(pais), minimumFractionDigits: decimales(pais) })}`
                : "-"}
            </div>
          </div>
          <div className="text-center p-2 bg-danger border border-dark m-3 text-white" style={{ borderRadius: "4px" }}>
            <div>
              <strong>90%</strong>
            </div>
            <div>
              {sumaImportes90 > 0
                ? `${moneda(pais)} ${sumaImportes90.toLocaleString(localString(pais), { maximumFractionDigits: decimales(pais), minimumFractionDigits: decimales(pais) })}`
                : "-"}
            </div>
          </div>
          <div className="text-center border border-dark p-2 bg-primary m-3 text-white" style={{ borderRadius: "4px" }}>
            <div>
              <strong>75%</strong>
            </div>
            <div>
              {sumaImportes75 > 0
                ? `${moneda(pais)} ${sumaImportes75.toLocaleString(localString(pais), { maximumFractionDigits: decimales(pais), minimumFractionDigits: decimales(pais) })}`
                : "-"}
            </div>
          </div>
          <div className="text-center p-2 bg-warning m-3 border border-dark text-dark" style={{ borderRadius: "4px" }}>
            <div>
              <strong>50%</strong>
            </div>
            <div>
              {sumaImportes50 > 0
                ? `${moneda(pais)} ${sumaImportes50.toLocaleString(localString(pais), { maximumFractionDigits: decimales(pais), minimumFractionDigits: decimales(pais) })}`
                : "-"}
            </div>
          </div>
          <div className="text-center p-2 bg-black m-3 border border-dark text-white" style={{ borderRadius: "4px" }}>
            <div>
              <strong>0%</strong>
            </div>
            <div>
              {sumaImportes0 > 0
                ? `${moneda(pais)} ${sumaImportes0.toLocaleString(localString(pais), { maximumFractionDigits: decimales(pais), minimumFractionDigits: decimales(pais) })}`
                : "-"}
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-center">
        <ResponsiveContainer width="80%" height={80} className="p-0 m-0">
          <BarChart layout="vertical" data={data} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
            {/* Deshabilitar los ticks y la línea del eje X */}
            <XAxis type="number" domain={[0, 100]} tick={false} axisLine={false} />
            <YAxis type="category" dataKey="name" hide />
            {/* Verificar si no hay datos y mostrar una barra gris oscura */}
            {data[0].empty > 0 ? (
              <Bar dataKey="empty" fill="#555555" isAnimationActive={false} radius={[10, 10, 10, 10]}>
                <LabelList dataKey="empty" position="center" formatter={() => "SIN DATOS"} fill="#FFF" />
              </Bar>
            ) : (
              <>
                {data[0]["100%"] > 0 && (
                  <Bar dataKey="100%" stackId="a" fill="#198754" radius={getRadius("100%", data[0])}>
                    <LabelList
                      dataKey="100%"
                      position="center"
                      formatter={(value) => `${Math.round(value)}%`}
                      fill="#fff"
                    />
                  </Bar>
                )}
                {data[0]["90%"] > 0 && (
                  <Bar dataKey="90%" stackId="a" fill="#dc3545" radius={getRadius("90%", data[0])}>
                    <LabelList
                      dataKey="90%"
                      position="center"
                      formatter={(value) => `${Math.round(value)}%`}
                      fill="#fff"
                    />
                  </Bar>
                )}
                {data[0]["75%"] > 0 && (
                  <Bar dataKey="75%" stackId="a" fill="#0d6efd" radius={getRadius("75%", data[0])}>
                    <LabelList
                      dataKey="75%"
                      position="center"
                      formatter={(value) => `${Math.round(value)}%`}
                      fill="#fff"
                    />
                  </Bar>
                )}
                {data[0]["50%"] > 0 && (
                  <Bar dataKey="50%" stackId="a" fill="#ffc107" radius={getRadius("50%", data[0])}>
                    <LabelList
                      dataKey="50%"
                      position="center"
                      formatter={(value) => `${Math.round(value)}%`}
                      fill="#000"
                    />
                  </Bar>
                )}
                {data[0]["0%"] > 0 && (
                  <Bar dataKey="0%" stackId="a" fill="#000000" radius={getRadius("0%", data[0])}>
                    <LabelList
                      dataKey="0%"
                      position="center"
                      formatter={(value) => `${Math.round(value)}%`}
                      fill="#fff"
                    />
                  </Bar>
                )}
              </>
            )}
          </BarChart>
        </ResponsiveContainer>
      </div>

      <div>
        {loading}
        <table className="table table-striped table-hover table-bordered table-sm" style={{ width: "100%" }}>
          <thead className="table-dark text-center align-middle">
            <tr>
              <th onClick={() => handleSort("id")}>ID {renderArrow("id")}</th>
              <th onClick={() => handleSort("cliente")}>CLIENTE {renderArrow("cliente")}</th>
              <th onClick={() => handleSort("mes")}>MES {renderArrow("mes")}</th>
              <th onClick={() => handleSort("importe")}>TICKET {renderArrow("importe")}</th>
              <th onClick={() => handleSort("estatus")}>STATUS {renderArrow("estatus")}</th>
              <th onClick={() => handleSort("formatopauta")}>FREC. {renderArrow("formatopauta")}</th>
              <th onClick={() => handleSort("periodopauta")}>PERÍODO {renderArrow("periodopauta")}</th>
              <th onClick={() => handleSort("edificiostotales")}>EDIFICIOS {renderArrow("edificiostotales")}</th>
              <th onClick={() => handleSort("pantallastotales")}>PANTALLAS {renderArrow("pantallastotales")}</th>
              <th onClick={() => handleSort("importePorPantalla")}>
                {moneda(pais)} POR PANTALLA {renderArrow("importePorPantalla")}
              </th>
              <th onClick={() => handleSort("intermediario")}>INTERMEDIARIO {renderArrow("intermediario")}</th>
              <th onClick={() => handleSort("ejecutivo")}>EJECUTIVO {renderArrow("ejecutivo")}</th>
              <th colSpan="2">EDITAR</th>
            </tr>
          </thead>

          <tbody className="table-striped">
            {sortedOportunidades.length === 0 ? (
              <tr>
                <td colSpan="13" className="text-center">
                  No hay ventas ni oportunidades según los filtros seleccionados :(
                </td>
              </tr>
            ) : (
              sortedOportunidades.map((oportunidad) => (
                <tr key={oportunidad.id}>
                  <td width="4%" className="text-center align-middle">
                    {oportunidad.id}
                  </td>
                  <td width="20%" className="text-start align-middle">
                    {oportunidad.cliente}
                  </td>
                  <td width="6%" className="text-center align-middle">
                    {mesEnLetras(oportunidad.mes)}
                  </td>
                  <td width="9%" className="text-end align-middle">
                    {moneda(pais)}{" "}
                    {oportunidad.importe.toLocaleString(localString(pais), {
                      maximumFractionDigits: decimales(pais),
                      minimumFractionDigits: decimales(pais),
                    })}
                  </td>
                  <td
                    width="6%"
                    className={`text-center align-middle ${
                      oportunidad.estatus === "100%"
                        ? "bg-success text-white"
                        : oportunidad.estatus === "90%"
                          ? "bg-danger text-white"
                          : oportunidad.estatus === "75%"
                            ? "bg-primary text-white"
                            : oportunidad.estatus === "50%"
                              ? "bg-warning text-dark"
                              : oportunidad.estatus === "0%"
                                ? "bg-black text-white"
                                : ""
                    }`}
                  >
                    {oportunidad.estatus}
                  </td>
                  <td width="6%" className="text-center align-middle">
                    {oportunidad.formatopauta}
                  </td>
                  <td width="6%" className="text-center align-middle">
                    {oportunidad.periodopauta}
                  </td>
                  <td width="6%" className="text-center align-middle">
                    {oportunidad.edificiostotales}
                  </td>
                  <td width="6%" className="text-center align-middle">
                    {oportunidad.pantallastotales}
                  </td>
                  <td width="6%" className="text-center align-middle">
                    {`${moneda(pais)} ${(oportunidad.importe / oportunidad.pantallastotales).toLocaleString(
                      localString(pais),
                      {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      }
                    )}`}
                  </td>
                  <td width="10%" className="text-center col-2 align-middle">
                    {oportunidad.intermediario}
                  </td>
                  <td width="8%" className="text-center col-1 align-middle">
                    {oportunidad.ejecutivo}
                  </td>
                  <td width="4%" className="text-center col-1 align-middle">
                    {oportunidad.facturanumero || oportunidad.estatus === "0%" ? (
                      "-"
                    ) : (
                      <FaEdit onClick={() => goToOportunidad(oportunidad.id)} />
                    )}
                  </td>
                  <td width="4%" className="text-center align-middle">
                    {oportunidad.facturanumero || oportunidad.estatus === "0%" ? (
                      "-"
                    ) : (
                      <FaTrash onClick={() => handleConfirmationModal(oportunidad)} />
                    )}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      <Modal isOpen={showConfirmationModal} toggle={() => setShowConfirmationModal(false)}>
        <ModalHeader>Confirmación</ModalHeader>
        <ModalBody>¿Está seguro de eliminar esta oportunidad?</ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setShowConfirmationModal(false)}>
            Cancelar
          </Button>
          <Button color="primary" onClick={() => handleDelete(deletedOpportunity.id)}>
            Eliminar
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
