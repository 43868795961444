/* eslint-disable no-console */
/* eslint-disable indent */
/* eslint-disable no-case-declarations */
import React, { useEffect, useCallback, useState, useRef } from "react";
import { Dropdown } from "react-bootstrap";
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer, LabelList } from "recharts";
import { useAuthContext } from "../../context/auth";
import { getCategorias, getOportunidades } from "../../utils";
import "./styles.scss";

export const VentasDetalleRegionReciente = () => {
  const [oportunidades, setOportunidades] = useState([]);
  const [categorias, setCategorias] = useState();
  const [orderBy, setOrderBy] = useState(null);
  const [orderDesc, setOrderDesc] = useState(false);
  const [filtroCliente, setFiltroCliente] = useState("");
  const [filtroMes, setFiltroMes] = useState([]);
  const [filtroEstatus, setFiltroEstatus] = useState([]);
  const [filtroIntermediario, setFiltroIntermediario] = useState("");
  const [filtroCategoria, setFiltroCategoria] = useState("");
  const [filtroPaises, setFiltroPaises] = useState({
    URU: true,
    PER: true,
    CHI: true,
  });
  const [checkboxStates, setCheckboxStates] = useState({
    URU: true,
    PER: true,
    CHI: true,
  });

  const { pais } = useAuthContext();

  const localString = useCallback(
    (pais) =>
      ({
        Chile: "es-CL",
        Perú: "es-PE",
        Uruguay: "es-UY",
      })[pais] || "es-UY",
    []
  );

  const paisCodigo = useCallback(
    (pais) =>
      ({
        Chile: "CHI",
        Perú: "PER",
        Uruguay: "URU",
      })[pais] || "URU",
    []
  );

  const tiposDeCambio = {
    Uruguay: 40,
    Perú: 3.75,
    Chile: 950,
  };

  const convertirADolares = (importe, monedaOrigen) => {
    const importeDolares = importe / tiposDeCambio[monedaOrigen];
    return Math.round(importeDolares);
  };

  const mesEnLetras = useCallback((mes) => {
    const mesesEnLetras = ["ENE", "FEB", "MAR", "ABR", "MAY", "JUN", "JUL", "AGO", "SEP", "OCT", "NOV", "DIC"];
    return mesesEnLetras[parseInt(mes) - 1] || "";
  }, []);

  const handleMesChange = useCallback((e) => {
    const selectedMonths = Array.from(
      e.target.closest(".dropdown-menu").querySelectorAll("input[type=checkbox]:checked"),
      (checkbox) => checkbox.value
    );
    setFiltroMes(selectedMonths);
  }, []);

  const renderMesOptions = () =>
    ["ENE", "FEB", "MAR", "ABR", "MAY", "JUN", "JUL", "AGO", "SEP", "OCT", "NOV", "DIC"].map((mes, index) => (
      <div key={index} className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          id={`mes${index}`}
          value={index < 9 ? `0${index + 1}` : `${index + 1}`}
          checked={filtroMes.includes(index < 9 ? `0${index + 1}` : `${index + 1}`)}
          onChange={handleMesChange}
          style={{ marginLeft: "-15px" }} // Aplicar margen izquierdo al checkbox
        />
        <label
          className="form-check-label"
          htmlFor={`mes${index}`}
          style={{ marginLeft: "10px" }} // Separar el texto del checkbox
        >
          {mes}
        </label>
      </div>
    ));

  const renderClientesOptions = () => {
    const clientesUnicos = new Set(oportunidades.map((oportunidad) => oportunidad.cliente));
    const clientesOrdenados = [...clientesUnicos].sort();

    return clientesOrdenados.map((cliente, index) => (
      <option key={index} value={cliente}>
        {cliente}
      </option>
    ));
  };

  const renderIntermediariosOptions = () => {
    const intermediariosOrdenados = [...new Set(oportunidades.map((oportunidad) => oportunidad.intermediario))].sort();
    return intermediariosOrdenados.map((intermediario, index) => (
      <option key={index} value={intermediario}>
        {intermediario}
      </option>
    ));
  };

  const renderEstatusOptions = () =>
    Object.entries(customStatusOrder).map(([estatus, _], index) => (
      <div key={index} className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          id={`estatus${index}`}
          value={estatus}
          checked={filtroEstatus.includes(estatus)}
          onChange={handleEstatusChange}
          style={{ marginLeft: "-15px" }} // Aplicar margen izquierdo al checkbox
        />
        <label
          className="form-check-label"
          htmlFor={`estatus${index}`}
          style={{ marginLeft: "10px" }} // Separar el texto del checkbox
        >
          {estatus}
        </label>
      </div>
    ));

  const handleEstatusChange = (e) => {
    const value = e.target.value;
    setFiltroEstatus((prev) => (prev.includes(value) ? prev.filter((estatus) => estatus !== value) : [...prev, value]));
  };

  const handlePaisCheckboxChange = (pais) => {
    setFiltroPaises((prevState) => ({
      ...prevState,
      [pais]: !prevState[pais],
    }));
  };

  useEffect(() => {
    setCheckboxStates((prevState) => ({
      ...prevState,
      ...filtroPaises,
    }));
  }, [filtroPaises]);

  const renderPaisesOptions = () =>
    Object.keys(filtroPaises).map((pais, index) => (
      <div key={index} className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          id={`pais${index}`}
          value={pais}
          checked={checkboxStates[pais]}
          onChange={() => handlePaisCheckboxChange(pais)}
          style={{ marginLeft: "-15px" }} // Aplicar margen izquierdo al checkbox
        />
        <label
          className="form-check-label"
          htmlFor={`pais${index}`}
          style={{ marginLeft: "10px" }} // Separar el texto del checkbox
        >
          {pais}
        </label>
      </div>
    ));

  useEffect(() => {
    const fetchData = async () => {
      try {
        const oportunidadesUruguay = await getOportunidades({ pais: "Uruguay" });
        const oportunidadesPeru = await getOportunidades({ pais: "Perú" });
        const oportunidadesChile = await getOportunidades({ pais: "Chile" });

        const allOportunidades = [...oportunidadesUruguay, ...oportunidadesPeru, ...oportunidadesChile];

        // Filtrar las oportunidades según la condición de 'updated_at'
        const oportunidadesFiltradas = allOportunidades.filter((oportunidad) => {
          const updatedDate = new Date(oportunidad.updated_at);
          const createdDate = new Date(oportunidad.created_at);
          const today = new Date();

          // Calcular la diferencia de días para updated_at y created_at
          const updatedDaysDifference = (today - updatedDate) / (1000 * 60 * 60 * 24);
          const createdDaysDifference = (today - createdDate) / (1000 * 60 * 60 * 24);

          // Filtrar si alguna de las diferencias es menor o igual a 16 días
          return updatedDaysDifference <= 16 || createdDaysDifference <= 16;
        });

        setOportunidades(oportunidadesFiltradas);

        // Obtener y establecer las categorías
        const newCategorias = await getCategorias();
        setCategorias(newCategorias);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const renderCategoriaOptions = () =>
    categorias
      ? categorias.map((categoria, index) => (
          <option key={index} value={categoria.nombre}>
            {categoria.nombre}
          </option>
        ))
      : null;

  const handleCategoriaChange = (e) => {
    setFiltroCategoria(e.target.value);
  };

  const oportunidadesFiltradas = oportunidades.filter((oportunidad) => {
    const clienteMatch = !filtroCliente || oportunidad.cliente.includes(filtroCliente);
    const mesMatch = filtroMes.length === 0 || filtroMes.includes(oportunidad.mes);
    const estatusMatch = filtroEstatus.length === 0 || filtroEstatus.includes(oportunidad.estatus);
    const intermediarioMatch = !filtroIntermediario || oportunidad.intermediario.includes(filtroIntermediario);
    const categoriaMatch = !filtroCategoria || oportunidad.categoria === filtroCategoria;
    const paisMatch = filtroPaises[paisCodigo(oportunidad.pais)];

    return (
      clienteMatch &&
      mesMatch &&
      estatusMatch &&
      intermediarioMatch &&
      categoriaMatch &&
      paisMatch &&
      !oportunidad.eliminada
    );
  });

  const customStatusOrder = {
    "100%": 0,
    "90%": 1,
    "75%": 2,
    "50%": 3,
    "0%": 4,
  };

  const handleSort = (column) => {
    if (orderBy === column) {
      setOrderDesc(!orderDesc);
    } else {
      setOrderBy(column);
      setOrderDesc(false);
    }
  };

  const sortedOportunidades = oportunidadesFiltradas.sort((a, b) => {
    let comparacion = 0;
    switch (orderBy) {
      case "pais":
      case "cliente":
      case "mes":
      case "categoria":
      case "intermediario":
      case "ejecutivo":
        comparacion = a[orderBy].localeCompare(b[orderBy]);
        break;
      case "estatus":
        comparacion = customStatusOrder[b.estatus] - customStatusOrder[a.estatus];
        break;
      case "importe":
        const importeA = convertirADolares(a.importe, a.pais);
        const importeB = convertirADolares(b.importe, b.pais);
        comparacion = importeA - importeB;
        break;
      default:
        comparacion = 0;
    }

    if (orderDesc) {
      comparacion *= -1;
    }

    return comparacion;
  });

  const renderArrow = (column) => {
    if (orderBy === column) {
      return orderDesc ? "↓" : "↑";
    } else {
      return null;
    }
  };

  const mesButtonRef = useRef(null);

  useEffect(() => {
    if (mesButtonRef.current) {
      mesButtonRef.current.addEventListener("click", (e) => {
        e.stopPropagation();
        e.preventDefault();
        mesButtonRef.current.nextSibling.classList.toggle("show");
      });

      document.addEventListener("click", () => {
        if (mesButtonRef.current && mesButtonRef.current.nextSibling) {
          mesButtonRef.current.nextSibling.classList.remove("show");
        }
      });
    }
  }, []);

  useEffect(() => {
    const setInitialOrder = () => {
      setOrderBy("estatus");
      setOrderDesc(true);
    };

    setInitialOrder();
  }, []);

  const sumaImportePorEstatus = (estatus) =>
    oportunidadesFiltradas
      .filter((oportunidad) => oportunidad.estatus === estatus)
      .reduce((acc, oportunidad) => acc + convertirADolares(oportunidad.importe, oportunidad.pais), 0);

  const sumaImportes100 = sumaImportePorEstatus("100%");
  const sumaImportes90 = sumaImportePorEstatus("90%");
  const sumaImportes75 = sumaImportePorEstatus("75%");
  const sumaImportes50 = sumaImportePorEstatus("50%");
  const sumaImportes0 = sumaImportePorEstatus("0%");

  // Calcular el total de importes para el gráfico
  const totalImportes = sumaImportes100 + sumaImportes90 + sumaImportes75 + sumaImportes50 + sumaImportes0;

  // Calcular los porcentajes de cada segmento basado en el total
  const data = [
    {
      name: "Total",
      "100%": (sumaImportes100 / totalImportes) * 100,
      "90%": (sumaImportes90 / totalImportes) * 100,
      "75%": (sumaImportes75 / totalImportes) * 100,
      "50%": (sumaImportes50 / totalImportes) * 100,
      "0%": (sumaImportes0 / totalImportes) * 100,
      empty: totalImportes === 0 ? 100 : 0, // Agrega un campo "empty" para cuando no hay datos
    },
  ];

  const getRadius = (key, data) => {
    const keys = Object.keys(data).filter((key) => data[key] > 0);
    const firstKey = keys[0];
    const lastKey = keys.at(-1);

    if (firstKey === key && lastKey === key) {
      return [10, 10, 10, 10]; // Todas las esquinas redondeadas
    } else if (firstKey === key) {
      return [10, 0, 0, 10]; // Redondear las esquinas izquierdas
    } else if (lastKey === key) {
      return [0, 10, 10, 0]; // Redondear las esquinas derechas
    }

    return [0, 0, 0, 0]; // Barras intermedias sin redondear
  };

  return (
    <div>
      <div className="row justify-content-center mt-2 mb-2">
        <div className="col-md-2">
          <select className="form-control" value={filtroCliente} onChange={(e) => setFiltroCliente(e.target.value)}>
            <option value="">Cliente</option>
            {renderClientesOptions()}
          </select>
        </div>
        <div className="col-md-2">
          <Dropdown>
            <Dropdown.Toggle variant="white border" id="dropdown-basic" className="form-control text-start">
              {filtroMes.length > 0 ? filtroMes.map((mes) => mesEnLetras(mes)).join(", ") : "Filtrar por mes/meses"}
            </Dropdown.Toggle>
            <Dropdown.Menu>{renderMesOptions()}</Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="col-md-2">
          <Dropdown>
            <Dropdown.Toggle variant="white border" id="dropdown-status" className="form-control text-start">
              {filtroEstatus.length > 0 ? filtroEstatus.join(", ") : "Filtrar por status"}
            </Dropdown.Toggle>
            <Dropdown.Menu>{renderEstatusOptions()}</Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="col-md-2">
          <select className="form-control" value={filtroCategoria} onChange={handleCategoriaChange}>
            <option value="">Categoría</option>
            {renderCategoriaOptions()}
          </select>
        </div>
        <div className="col-md-2">
          <select
            className="form-control"
            value={filtroIntermediario}
            onChange={(e) => setFiltroIntermediario(e.target.value)}
          >
            <option value="">Intermediario</option>
            {renderIntermediariosOptions()}
          </select>
        </div>
        <div className="col-md-2">
          <Dropdown>
            <Dropdown.Toggle variant="white border" id="dropdown-paises" className="form-control text-start">
              {Object.values(filtroPaises).some((v) => v) ? "País/es seleccionados" : "País/es"}
            </Dropdown.Toggle>
            <Dropdown.Menu>{renderPaisesOptions()}</Dropdown.Menu>
          </Dropdown>
        </div>
      </div>

      {/* Mostrar siempre las sumas por estatus */}
      <div className="mb-3 d-flex justify-content-center">
        <div className="d-flex justify-content-between" style={{ width: "80%" }}>
          <div className="text-center p-2 bg-success text-white border border-dark m-2" style={{ borderRadius: "4px" }}>
            <div>
              <strong>100%</strong>
            </div>
            <div>
              {sumaImportes100 > 0
                ? `US$ ${sumaImportes100.toLocaleString(localString(pais), {
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0,
                  })}`
                : "-"}
            </div>
          </div>
          <div className="text-center p-2 bg-danger text-white border border-dark m-2" style={{ borderRadius: "4px" }}>
            <div>
              <strong>90%</strong>
            </div>
            <div>
              {sumaImportes90 > 0
                ? `US$ ${sumaImportes90.toLocaleString(localString(pais), {
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0,
                  })}`
                : "-"}
            </div>
          </div>
          <div className="text-center p-2 bg-primary text-white border border-dark m-2" style={{ borderRadius: "4px" }}>
            <div>
              <strong>75%</strong>
            </div>
            <div>
              {sumaImportes75 > 0
                ? `US$ ${sumaImportes75.toLocaleString(localString(pais), {
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0,
                  })}`
                : "-"}
            </div>
          </div>
          <div className="text-center p-2 bg-warning text-dark border border-dark m-2" style={{ borderRadius: "4px" }}>
            <div>
              <strong>50%</strong>
            </div>
            <div>
              {sumaImportes50 > 0
                ? `US$ ${sumaImportes50.toLocaleString(localString(pais), {
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0,
                  })}`
                : "-"}
            </div>
          </div>
          <div className="text-center p-2 bg-dark text-white border border-dark m-2" style={{ borderRadius: "4px" }}>
            <div>
              <strong>0%</strong>
            </div>
            <div>
              {sumaImportes0 > 0
                ? `US$ ${sumaImportes0.toLocaleString(localString(pais), {
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0,
                  })}`
                : "-"}
            </div>
          </div>
        </div>
      </div>

      {/* Gráfico de barras horizontal */}
      <div className="d-flex justify-content-center">
        <ResponsiveContainer width="100%" height={80}>
          <BarChart layout="vertical" data={data}>
            <XAxis type="number" domain={[0, 100]} tick={false} axisLine={false} />
            <YAxis type="category" dataKey="name" hide />

            {/* Mostrar barra gris si no hay datos */}
            {totalImportes === 0 ? (
              <Bar dataKey="empty" fill="#6c757d" isAnimationActive={false} radius={[10, 10, 10, 10]}>
                <LabelList dataKey="empty" position="center" formatter={() => "SIN DATOS"} fill="#fff" />
              </Bar>
            ) : (
              <>
                {data[0]["100%"] > 0 && (
                  <Bar dataKey="100%" stackId="a" fill="#198754" radius={getRadius("100%", data[0])}>
                    <LabelList
                      dataKey="100%"
                      position="center"
                      formatter={(value) => `${Math.round(value)}%`}
                      fill="#fff"
                    />
                  </Bar>
                )}
                {data[0]["90%"] > 0 && (
                  <Bar dataKey="90%" stackId="a" fill="#dc3545" radius={getRadius("90%", data[0])}>
                    <LabelList
                      dataKey="90%"
                      position="center"
                      formatter={(value) => `${Math.round(value)}%`}
                      fill="#fff"
                    />
                  </Bar>
                )}
                {data[0]["75%"] > 0 && (
                  <Bar dataKey="75%" stackId="a" fill="#0d6efd" radius={getRadius("75%", data[0])}>
                    <LabelList
                      dataKey="75%"
                      position="center"
                      formatter={(value) => `${Math.round(value)}%`}
                      fill="#fff"
                    />
                  </Bar>
                )}
                {data[0]["50%"] > 0 && (
                  <Bar dataKey="50%" stackId="a" fill="#ffc107" radius={getRadius("50%", data[0])}>
                    <LabelList
                      dataKey="50%"
                      position="center"
                      formatter={(value) => `${Math.round(value)}%`}
                      fill="#000"
                    />
                  </Bar>
                )}
                {data[0]["0%"] > 0 && (
                  <Bar dataKey="0%" stackId="a" fill="#000000" radius={getRadius("0%", data[0])}>
                    <LabelList
                      dataKey="0%"
                      position="center"
                      formatter={(value) => `${Math.round(value)}%`}
                      fill="#fff"
                    />
                  </Bar>
                )}
              </>
            )}
          </BarChart>
        </ResponsiveContainer>
      </div>

      <div>
        <table className="table table-striped table-hover table-bordered table-sm" style={{ width: "100%" }}>
          <thead className="table-dark text-center align-middle">
            <tr>
              <th onClick={() => handleSort("pais")}>PAÍS {renderArrow("pais")}</th>
              <th onClick={() => handleSort("cliente")}>CLIENTE {renderArrow("cliente")}</th>
              <th onClick={() => handleSort("mes")}>MES {renderArrow("mes")}</th>
              <th onClick={() => handleSort("importe")}>TICKET {renderArrow("importe")}</th>
              <th onClick={() => handleSort("estatus")}>STATUS {renderArrow("estatus")}</th>
              <th onClick={() => handleSort("categoria")}>CATEGORÍA {renderArrow("categoria")}</th>
              <th onClick={() => handleSort("intermediario")}>INTERMEDIARIO {renderArrow("intermediario")}</th>
              <th onClick={() => handleSort("ejecutivo")}>EJECUTIVO {renderArrow("ejecutivo")}</th>
            </tr>
          </thead>

          <tbody className="table-striped">
            {sortedOportunidades.length === 0 ? (
              <tr>
                <td colSpan="13" className="text-center">
                  <p>No hay ventas ni oportunidades según los filtros seleccionados :(</p>
                </td>
              </tr>
            ) : (
              sortedOportunidades.map((oportunidad) => (
                <tr key={oportunidad.id}>
                  <td className="text-center col-1 align-middle">{paisCodigo(oportunidad.pais)}</td>
                  <td className="text-start col-2 align-middle">{oportunidad.cliente}</td>
                  <td className="text-center col-1 align-middle">{mesEnLetras(oportunidad.mes)}</td>
                  <td className="text-end col-1 align-end">
                    US$ {convertirADolares(oportunidad.importe, oportunidad.pais).toLocaleString(localString(pais))}
                  </td>
                  <td
                    className={`text-center col-1 align-middle ${
                      oportunidad.estatus === "100%"
                        ? "bg-success text-white"
                        : oportunidad.estatus === "90%"
                          ? "bg-danger text-white"
                          : oportunidad.estatus === "75%"
                            ? "bg-primary text-white"
                            : oportunidad.estatus === "50%"
                              ? "bg-warning text-dark"
                              : "bg-dark text-white"
                    }`}
                  >
                    {oportunidad.estatus}
                  </td>
                  <td className="text-center col-2 align-middle">{oportunidad.categoria}</td>
                  <td className="text-center col-2 align-middle">{oportunidad.intermediario}</td>
                  <td className="text-center col-1 align-middle">{oportunidad.ejecutivo}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};
