import { BsBuildingUp } from "react-icons/bs";
import { BsBuildingCheck } from "react-icons/bs";
import { BsFileSpreadsheet } from "react-icons/bs";
// import { CiUser } from "react-icons/ci";
import { BsListColumnsReverse } from "react-icons/bs";
import { BsFillPinMapFill } from "react-icons/bs";
import { GiChart } from "react-icons/gi";
import { GiSouthAmerica } from "react-icons/gi";
import { HiOutlineUsers } from "react-icons/hi2";
import { IoBusiness } from "react-icons/io5";
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";
import { LuClipboardList } from "react-icons/lu";
// import { MdDashboard } from "react-icons/md";
import { MdOutlineCategory } from "react-icons/md";
import { PiSuitcaseSimpleThin } from "react-icons/pi";
import { SiAffinitydesigner } from "react-icons/si";
import { TfiMoney } from "react-icons/tfi";
import { Link } from "react-router-dom";
import { Container, Loader } from "../../components";
import { ROLES } from "../../constants/roles";
import { useAuthContext } from "../../context/auth";
import { logout } from "../../utils";
import "./styles.scss";

export const Admin = () => {
  const { session, nombre, rol } = useAuthContext();

  if (session === undefined) return <Loader fullPage />;

  const nombreUsuario = nombre || "usuario";

  return (
    <Container className="Admin" header titulo="PANEL ADMINISTRACIÓN">
      <p>Hola {nombreUsuario} :)</p>
      <div className="botones row align-middle">
        {rol === ROLES.SUPERADMIN && (
          <>
            {/* <div className="col-3">
              <Link to="/admin/ventas-dashboard" className="link">
                <MdDashboard className="icon" size={56} />
                <span>Dashboard</span>
              </Link>
            </div> */}
            <div className="col-3">
              <Link to="/admin/ventas-detalle" className="link">
                <TfiMoney className="icon" size={56} />
                <span>Ventas</span>
              </Link>
            </div>
            <div className="col-3">
              <Link to="/admin/ventas-proyeccion" className="link">
                <GiChart className="icon" size={56} />
                <span>Proyección Comercial</span>
              </Link>
            </div>
            <div className="col-3">
              <Link to="/admin/ventas-region" className="link">
                <GiSouthAmerica className="icon" size={56} />
                <span>Región</span>
              </Link>
            </div>
            <div className="col-3">
              <Link to="/admin/online-cotizador" className="link">
                <BsFileSpreadsheet className="icon" size={56} />
                <span>Cotizador</span>
              </Link>
            </div>
            <div className="col-3">
              <Link to="/admin/cargar-intermediario" className="link">
                <LuClipboardList className="icon" size={56} />
                <span>Cargar Agencia</span>
              </Link>
            </div>
            <div className="col-3">
              <Link to="/admin/cargar-cliente" className="link">
                <LuClipboardList className="icon" size={56} />
                <span>Cargar Cliente</span>
              </Link>
            </div>
            <div className="col-3">
              <Link to="/admin/cargar-oportunidad" className="link">
                <LuClipboardList className="icon" size={56} />
                <span>Cargar Oportunidad</span>
              </Link>
            </div>
            <div className="col-3">
              <Link to="/admin/ventas-facturas" className="link">
                <LiaFileInvoiceDollarSolid className="icon" size={56} />
                <span>Facturación</span>
              </Link>
            </div>
            <div className="col-3">
              <Link to="/admin/informes-comision" className="link">
                <LuClipboardList className="icon" size={56} />
                <span>Comisión Edificios</span>
              </Link>
            </div>
            <div className="col-3">
              <Link to="/admin/usuarios" className="link">
                <HiOutlineUsers className="icon" size={56} />
                <span>Usuarios</span>
              </Link>
            </div>
            <div className="col-3">
              <Link to="/admin/cargar-edificio" className="link">
                <BsBuildingUp className="icon" size={56} />
                <span>Cargar Edificios</span>
              </Link>
            </div>
            <div className="col-3">
              <Link to="/admin/edificios" className="link">
                <BsBuildingCheck className="icon" size={56} />
                <span>Edificios</span>
              </Link>
            </div>
            <div className="col-3">
              <Link to="/categorias/automotriz" className="link">
                <MdOutlineCategory className="icon" size={56} />
                <span>Análisis Categorías</span>
              </Link>
            </div>
            {/* <div className="col-3">
              <Link to="/admin/perfil" className="link">
                <CiUser className="icon" size={56} />
                <span>Perfil</span>
              </Link>
            </div> */}
          </>
        )}
        {rol === ROLES.COMERCIAL && (
          <>
            <div className="col-3">
              <Link to="/admin/online-cotizador" className="link">
                <BsFileSpreadsheet className="icon" size={56} />
                <span>Cotizador</span>
              </Link>
            </div>
            <div className="col-3">
              <Link to="/inventario" className="link">
                <IoBusiness className="icon" size={56} />
                <span>Inventario</span>
              </Link>
            </div>
            <div className="col-3">
              <Link to="/mediakit" className="link">
                <SiAffinitydesigner className="icon" size={56} />
                <span>Mediakit</span>
              </Link>
            </div>
            <div className="col-3">
              <Link to="/portafolio" className="link">
                <PiSuitcaseSimpleThin className="icon" size={56} />
                <span>Portafolio</span>
              </Link>
            </div>
            <div className="col-3">
              <Link to="/listadoempresas" className="link">
                <BsListColumnsReverse className="icon" size={56} />
                <span>Listado Empresas</span>
              </Link>
            </div>
            <div className="col-3">
              <Link to="/mapa" className="link">
                <BsFillPinMapFill className="icon" size={56} />
                <span>Mapa</span>
              </Link>
            </div>
            <div className="col-3">
              <Link to="/admin/ventas-detalle" className="link">
                <TfiMoney className="icon" size={56} />
                <span>Ventas</span>
              </Link>
            </div>
            <div className="col-3">
              <Link to="/admin/cargar-intermediario" className="link">
                <LuClipboardList className="icon" size={56} />
                <span>Cargar Agencia</span>
              </Link>
            </div>
            <div className="col-3">
              <Link to="/admin/cargar-cliente" className="link">
                <LuClipboardList className="icon" size={56} />
                <span>Cargar Cliente</span>
              </Link>
            </div>
            <div className="col-3">
              <Link to="/admin/cargar-oportunidad" className="link">
                <LuClipboardList className="icon" size={56} />
                <span>Cargar Oportunidad</span>
              </Link>
            </div>
            <div className="col-3">
              <Link to="/admin/ventas-proyeccion" className="link">
                <GiChart className="icon" size={56} />
                <span>Proyección Comercial</span>
              </Link>
            </div>
            <div className="col-3">
              <Link to="/admin/ventas-region" className="link">
                <GiSouthAmerica className="icon" size={56} />
                <span>Región</span>
              </Link>
            </div>
            {/* <div className="col-3">
              <Link to="/admin/ventas-dashboard" className="link">
                <MdDashboard className="icon" size={56} />
                <span>Dashboard</span>
              </Link>
            </div> */}
            <div className="col-3">
              <Link to="/admin/usuarios" className="link">
                <HiOutlineUsers className="icon" size={56} />
                <span>Usuarios</span>
              </Link>
            </div>
            <div className="col-3">
              <Link to="/categorias/automotriz" className="link">
                <MdOutlineCategory className="icon" size={56} />
                <span>Análisis Categorías</span>
              </Link>
            </div>
          </>
        )}
        {rol === ROLES.ADMINISTRATIVO && (
          <>
            <div className="col-3">
              <Link to="/admin/ventas-facturas" className="link">
                <LiaFileInvoiceDollarSolid className="icon" size={56} />
                <span>Facturación</span>
              </Link>
            </div>
            <div className="col-3">
              <Link to="/admin/informes-comision" className="link">
                <LuClipboardList className="icon" size={56} />
                <span>Comisión Edificios</span>
              </Link>
            </div>
            <div className="col-3">
              <Link to="/admin/ventas-detalle" className="link">
                <TfiMoney className="icon" size={56} />
                <span>Ventas</span>
              </Link>
            </div>
            <div className="col-3">
              <Link to="/admin/usuarios" className="link">
                <HiOutlineUsers className="icon" size={56} />
                <span>Usuarios</span>
              </Link>
            </div>
          </>
        )}
        {rol === ROLES.EDIFICIO && (
          <>
            <div className="col-3">
              <Link to="/admin/informes-comision" className="link">
                <LuClipboardList className="icon" size={56} />
                <span>Comisión Edificios</span>
              </Link>
            </div>
          </>
        )}
        {rol === ROLES.AGENCIA && (
          <>
            <div className="col-3">
              <Link to="/inventario" className="link">
                <IoBusiness className="icon" size={56} />
                <span>Inventario</span>
              </Link>
            </div>
            <div className="col-3">
              <Link to="/mediakit" className="link">
                <SiAffinitydesigner className="icon" size={56} />
                <span>Mediakit</span>
              </Link>
            </div>
            <div className="col-3">
              <Link to="/portafolio" className="link">
                <PiSuitcaseSimpleThin className="icon" size={56} />
                <span>Portafolio</span>
              </Link>
            </div>
            <div className="col-3">
              <Link to="/listadoempresas" className="link">
                <BsListColumnsReverse className="icon" size={56} />
                <span>Listado Empresas</span>
              </Link>
            </div>
            <div className="col-3">
              <Link to="/mapa" className="link">
                <BsFillPinMapFill className="icon" size={56} />
                <span>Mapa</span>
              </Link>
            </div>
            <div className="col-3">
              <Link to="/admin/online-cotizador" className="link">
                <BsFileSpreadsheet className="icon" size={56} />
                <span>Cotizador</span>
              </Link>
            </div>
            <div className="col-3">
              <Link to="/categorias/automotriz" className="link">
                <MdOutlineCategory className="icon" size={56} />
                <span>Análisis Categorías</span>
              </Link>
            </div>
          </>
        )}
      </div>
      <div className="col-12 mt-5">
        <button className="btn btn-primary" type="submit" onClick={logout}>
          LOGOUT
        </button>
      </div>
    </Container>
  );
};
