import { BsCalendarWeek } from "react-icons/bs";
import { HiListBullet } from "react-icons/hi2";
import { MdDashboard } from "react-icons/md";
import { Link } from "react-router-dom";
import { BlackArrow, Container } from "../../components";
import "./styles.scss";

const VentasRegion = () => (
  <div>
    <BlackArrow />
    <Container className="Admin" header titulo="STATUS COMERCIAL REGIONAL">
      <div className="botones row">
        <div className="col-4">
          <Link to="/admin/ventas-region-detalle" className="link">
            <HiListBullet className="icon" size={56} />
            <span>TODAS</span>
          </Link>
        </div>
        <div className="col-4">
          <Link to="/admin/ventas-region-recientes" className="link">
            <BsCalendarWeek className="icon" size={56} />
            <span>ÚLTIMOS 14 DÍAS</span>
          </Link>
        </div>
        <div className="col-4">
          <Link to="/admin/ventas-region-dashboard" className="link">
            <MdDashboard className="icon" size={56} />
            <span>DASHBOARD</span>
          </Link>
        </div>
      </div>
    </Container>
  </div>
);

export default VentasRegion;
