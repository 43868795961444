import { BlackArrow, Container, VentasDetalleRegion } from "../../components";

const VentasRegionDetalle = () => (
  <div>
    <BlackArrow />
    <Container header titulo="OPORTUNIDADES REGIÓN">
      <VentasDetalleRegion />
    </Container>
  </div>
);

export default VentasRegionDetalle;
